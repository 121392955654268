import React from "react"
import { graphql, Link } from "gatsby"
import Banner from "../components/banner/banner"
import Img from "gatsby-image"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"

export default function Contact({ data }) {
  return (
    <Layout>
      <SEO title="Site Map" />
      <Banner
        image={
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        }
        title="Site Map"
      />
      <div className="width75 center">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
            <ul>
              <li>
                <Link to="/about/charges-fees">Charges and Fees</Link>
              </li>
              <li>
                <Link to="/about/our-team">Our Team</Link>
              </li>
              <li>
                <Link to="/about/complaints-procedure">
                  Complaints Procedure
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/services">Services</Link>
            <ul>
              <li>
                <Link to="/services/immigration">Immigration</Link>
              </li>
              <li>
                <Link to="/services/criminal-law">Criminal Law</Link>
              </li>
              <li>
                <Link to="/services/family-law">Family Law</Link>
              </li>
              <li>
                <Link to="/services/personal-injury">Personal Injury</Link>
              </li>
              <li>
                <Link to="/services/civil-litigation">Civil Litigation</Link>
              </li>
              <li>
                <Link to="/services/wills-in-islam">Wills in Islam</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
          <li>
            <Link to="/site-map">Site Map</Link>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "london-skyline.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
